@use 'styles/breakpoints' as *;

.menuWrapper {
  display: flex;
  margin-right: 1.5rem;
  align-items: center;

  & > button {
    @include desktop {
      margin-left: 3rem;
    }

    @include xlDesktop {
      margin-left: 6rem;
    }
  }
}

.navList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  display: none;

  @include desktop {
    display: flex;
    gap: 3rem;
  }

  @include xlDesktop {
    gap: 5rem;
  }
}

.headerWrapper {
  font-family: var(--inter-font);
  position: fixed;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  min-width: 100%;

  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1);
  top: 0;
  left: 0;

  z-index: 20;

  padding: 1.25rem 1rem 1.25rem 2rem;

  @include desktop {
    padding-right: 2rem;
  }

  &.open {
    background-color: var(--clr-white);
  }

  &:not(.open).inverted {
    color: var(--clr-white);
  }

  &.isHidden {
    top: calc((var(--menu-nav-height) + 1.5rem) * -1);
  }
}

.navigation {
  position: relative;
  z-index: 20;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 2.5rem;
}
